import { useNavigate, useParams } from '@solidjs/router';
import { Component, createEffect, For, onMount, Show } from 'solid-js';
import { NEXT, NO, PREVIOUS, YES } from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import { Radio } from '~/components/common/buttons/Radio';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationStep } from '~/types/RentalApplicationSteps';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationQuestionsUseCase } from '~/use-cases/rental-applications/getRentalApplicationQuestionsUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';

export interface RentalApplicationQuestionsDto {
  questions: string[] | null;
}

export const QuestionsStep: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams();

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: getQuestions, isLoading: isLoadingQuestions } = useUseCase(GetRentalApplicationQuestionsUseCase);
  const { execute: goToNextStep, isLoading: isGoingToNextStep } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);

  onMount(async () => {
    await getQuestions({ id: params.id, password: params.password });
  });

  const updateAnswer = (index: number, value: boolean) => {
    const questions = presentableRentalApplication()?.questions ?? [];
    const updatedQuestions = questions.map((question, i) => {
      const newQuestion = { ...question };
      if (i === index) {
        newQuestion.answer = value;
      }
      return newQuestion;
    });
    updateRentalApplication({ questions: updatedQuestions });
  };

  createEffect(() => {
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasApplicantInfoError) {
      return;
    }
    goToNextStep({
      currentStep: RentalApplicationStep.Questions,
      credentials: { id: params.id, password: params.password },
    });
  });

  return (
    <Show
      when={!isLoadingQuestions() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="flex h-section3 flex-col bg-white p-6">
        <ol class="ordered-list flex flex-col gap-3 rounded-2xl text-black">
          <For each={presentableRentalApplication()?.questions}>
            {(question, index) => (
              <li class="flex justify-between">
                <span>{question.question}</span>
                <Radio
                  items={[
                    { label: t(YES), value: true },
                    { label: t(NO), value: false },
                  ]}
                  value={question.answer}
                  onInput={(value) => updateAnswer(index(), value)}
                />
              </li>
            )}
          </For>
        </ol>
      </div>
      <div class="fixed bottom-0 left-0 z-20 w-full border-t border-gray-200 bg-white px-4 py-3">
        <div class="flex justify-between">
          <Button type="button" onClick={() => navigate(`/rental-applications/${params.id}/${params.password}/employment-income`)}>
            {t(PREVIOUS)}
          </Button>
          <Button type="button" onClick={validateRentalApplication}>
            {t(NEXT)}
          </Button>
        </div>
      </div>
    </Show>
  );
};
