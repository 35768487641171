import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { MagicUseCase } from '../magicUseCase';
import { GetRentalApplicationUseCase } from './getRentalApplicationUseCase';

export class GetRentalApplicationQuestionsUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    const response = await rentalApplicationGateway.getApplicationQuestions();
    if (!this.getState().user.rentalApplication.application) {
      await new GetRentalApplicationUseCase().execute(credentials);
    }
    const application = this.getState().user.rentalApplication.application;
    if (application) {
      application.questions = response.questions?.map((question: any) => {
        return {
          question,
          answer: false,
        };
      });
    }
  }
}
